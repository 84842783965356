<template>
  <div class="classroom-details-page">
    <v-row justify="center" class="pa-1">
      <v-col md="6" v-if="classroom">
        <!-- main classroom details -->
        <v-card elevation="0">
          <!-- <v-card-title>{{classroom.sections}} <p class="ma-0 ml-2 font-weight-bold text-caption">{{classroom.active_students}} Students</p><v-spacer></v-spacer><v-btn>View All</v-btn></v-card-title> -->
          <v-list-item class="px-2">
            <v-list-item-content>
              <v-list-item-title
                ><span class="text-h5 font-weight-bold mr-2">{{
                  classroom.sections
                }}</span>
                <!-- <span
                  >{{ classroom.total_students }} Students</span
                > -->
              </v-list-item-title>
              <!-- <v-list-item-subtitle
                >{{ classroom.total_students }} Students</v-list-item-subtitle
              > -->
            </v-list-item-content>
            <v-list-item-icon class="align-self-center ma-0"
              ><v-btn
                class="non-focused px-0"
                color="primary"
                text
                :to="{
                  name: 'ClassroomStudents',
                  params: { roomId: $route.params.roomId },
                }"
                >View All</v-btn
              ></v-list-item-icon
            >
          </v-list-item>
        </v-card>
        <!-- student cards: active, inactive, etc -->
        <v-row dense class="mx-1">
          <v-col cols="4" class="text-center">
            <v-card
              outlined
              elevation="0"
              class="py-5"
              :to="{
                name: 'ClassroomStudents',
                params: { roomId: this.$route.params.roomId },
              }"
            >
              <h1 class="primary--text">{{ classroom.total_students }}</h1>
              <p class="body-2 text-truncate ma-0">Students</p>
            </v-card>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-card
              outlined
              elevation="0"
              class="py-5"
              :to="{
                name: 'ClassroomStudents',
                params: { roomId: this.$route.params.roomId },
              }"
            >
              <h1 class="primary--text">{{ classroom.active_students }}</h1>
              <p class="body-2 ma-0">Logged In</p>
            </v-card>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-card
              outlined
              elevation="0"
              class="py-5"
              :to="{
                name: 'ClassroomStudents',
                params: { roomId: this.$route.params.roomId },
              }"
            >
              <h1 class="primary--text">
                {{ classroom.total_students - classroom.active_students }}
              </h1>
              <p class="body-2 ma-0">Logged Out</p>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mt-4 mx-2"></v-divider>

        <v-divider class="mx-2"></v-divider>

        <!-- Class Teacher Alloted -->

        <div v-if="classroom.class_teachers" class="mt-2 mx-3">
          <v-list-item>
            <v-list-item-subtitle class="font-weight-medium"
              >Class Teachers</v-list-item-subtitle
            >
            <v-btn
              small
              color="primary"
              @click="showClassTeacherSelectionSheet = true"
              >Assign
            </v-btn>
          </v-list-item>
          <v-list-item v-for="class_teacher in classroom.class_teachers" :key="class_teacher.id">
            <v-list-item-avatar>
              <v-img :src="class_teacher.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-title class="font-weight-medium">
              {{ class_teacher.full_name }}
            </v-list-item-title>
            <v-icon @click="showRemoveClassTeacherDialog = true; removingClassTeacher = class_teacher"
              >mdi-close</v-icon
            >
          </v-list-item>
          <v-list-item-subtitle class="mx-4 font-weight-medium">
            Edit Access?
          </v-list-item-subtitle>
          <v-list-item>
            <v-list-item-subtitle class="font-weight-medium text-wrap pt-0 mt-0"
              >If enabled, Class Teacher will be able to edit fee details of the
              students of this class.</v-list-item-subtitle
            >
            <v-checkbox
              v-model="classroom.edit"
              @change="changeEditStatus"
            ></v-checkbox>
          </v-list-item>
        </div>

        <!-- Assign Class Teacher -->
        <div v-else class="class_teacher mt-2 mx-3">
          <h4 class="mt-3">Class Teachers</h4>
          <v-list-item>
            <v-list-item-subtitle class="font-weight-medium"
              >Not Assigned</v-list-item-subtitle
            >
            <v-btn
              small
              color="primary"
              @click="showClassTeacherSelectionSheet = true"
              >Assign
            </v-btn>
          </v-list-item>
        </div>

        <v-divider class="mx-2 mt-2"></v-divider>

        <confirmation-dialog
          :visible="showRemoveClassTeacherDialog && removingClassTeacher"
          title="Are you sure you want to remove Class Teacher?"
          @successCallback="removeClassTeacher"
          @failureCallback="showRemoveClassTeacherDialog = false; removingClassTeacher = null;"
        />

        <confirmation-dialog
          :visible="showAssignClassTeacherDialog"
          title="Are you sure you want to assign Class Teacher?"
          @successCallback="assignClassTeacher"
          @failureCallback="
            showAssignClassTeacherDialog = false;
            selectedStaff = null;
          "
        />

        <!-- Assign Class Teacher(List of staff) -->

        <intract-list-sheet
          title="Select Class Teacher"
          :visible="showClassTeacherSelectionSheet"
          @close="showClassTeacherSelectionSheet = false"
        >
          <template v-slot:list>
            <intract-smart-list
              class="mt-2"
              :endpoint="endpoints.staffViewSet"
              paginated
              :item-options="{
                title: 'full_name',
                avatar: 'image',
                handleClick: handleStaffSelectionClick,
              }"
            >
              <template v-slot:list-item-content="{ item: staff }">
                <v-list-item-content>
                  <v-list-item-title>{{ staff.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    staff.username
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </intract-smart-list>
          </template>
        </intract-list-sheet>

        <!-- subjects management -->
        <div class="subjects mt-2 mx-3">
          <h4 class="my-3">Subjects</h4>
          <v-expansion-panels class="mt-1" v-if="subjects.length">
            <v-expansion-panel v-for="subject in subjects" :key="subject.id">
              <v-expansion-panel-header class="px-4 py-2">
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ subject.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-if="subject.room">{{ subject.sections }}</span>
                      <span v-else>
                        <span
                          v-for="room in subject.eligible_rooms"
                          :key="room.id"
                          >{{ room.sections }}
                          <span
                            v-if="
                              subject.eligible_rooms.length > 1 &&
                              subject.eligible_rooms.indexOf(room) !=
                                subject.eligible_rooms.length - 1
                            "
                            >,
                          </span>
                        </span>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="!subject.room">
                    <v-chip small>Extra</v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="text-center">
                  <v-btn
                    v-if="subject.room"
                    @click="
                      subjectOptions.selectedSubject = subject;
                      subjectOptions.visible = true;
                    "
                    block
                    small
                    color="primary"
                    outlined
                    class="non-focused"
                    >More Subject Options
                    <v-icon class="ml-2" size="22"
                      >mdi-arrow-down-drop-circle-outline</v-icon
                    >
                  </v-btn>
                  <v-divider class="mt-2"></v-divider>
                  <p class="ma-0 my-1 font-weight-medium">Assigned Teachers</p>
                  <v-divider></v-divider>
                </div>
                <div v-if="subject.faculty.length">
                  <v-list-item
                    class="px-0 non-focused"
                    v-for="staff in subject.faculty"
                    :key="staff.id"
                    :to="{
                      name: 'StaffDetails',
                      params: { staffId: staff.id },
                    }"
                  >
                    <v-list-item-avatar
                      ><v-img :src="staff.image"></v-img
                    ></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        staff.full_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        staff.username
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-else>
                  <p class="ma-0">No faculty assigned</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-else-if="!isLoading" class="text-center">
            <p class="ma-0">No subjects found.</p>
            <v-btn
              class="mt-2"
              color="primary"
              @click="createEditSubjectSheet.visible = true"
              >Create Subject</v-btn
            >
          </div>
        </div>

        <v-divider class="mx-2 mt-6"></v-divider>

        <!-- Extra subjects management -->
        <!-- <div class="subjects mt-2 mx-3">
          <h4 class="my-3">Extra Subjects</h4>
          <v-expansion-panels class="mt-1" v-if="extraSubjects.length">
            <v-expansion-panel v-for="subject in extraSubjects" :key="subject.id">
              <v-expansion-panel-header>
                <p class="ma-0 text-truncate">
                  <span class="font-weight-medium">{{ subject.name }}</span>
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="text-center">
                  <v-divider class="mt-2"></v-divider>
                  <p class="ma-0 my-1 font-weight-medium">Assigned Teachers</p>
                  <v-divider></v-divider>
                </div>
                <div v-if="subject.faculty.length">
                  <v-list-item
                    class="px-0 non-focused"
                    v-for="staff in subject.faculty"
                    :key="staff.id"
                    :to="{
                      name: 'StaffDetails',
                      params: { staffId: staff.id },
                    }"
                  >
                    <v-list-item-avatar
                      ><v-img :src="staff.image"></v-img
                    ></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        staff.full_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        staff.username
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-else>
                  <p class="ma-0">No faculty assigned</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div> -->
      </v-col>
    </v-row>
    <!-- subject options bottom sheet -->
    <intract-list-sheet
      :visible="subjectOptions.visible"
      :title="
        subjectOptions.selectedSubject
          ? subjectOptions.selectedSubject.name
          : ''
      "
      :list="subjectOptionItems"
      @close="subjectOptions.visible = false"
    />
    <!-- subjects create edit sheet -->
    <intract-create-edit-sheet
      persistent
      key="subject-create-edit-sheet"
      class="subject-create-edit-sheet"
      :title="createEditSubjectSheet.editId ? 'Edit Subject' : 'Create Subject'"
      :visible="createEditSubjectSheet.visible"
      @close="
        createEditSubjectSheet.visible = false;
        createEditSubjectSheet.editId = null;
        createEditSubjectSheet.obj.image = null;
      "
      :fields="formFields"
      :endpoint="endpoints.subjectViewSet"
      :edit-id="createEditSubjectSheet.editId"
      :data-object="createEditSubjectSheet.obj"
      create-success-message="Subject created successfully!"
      edit-success-message="Subject edited successfully!"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditSubjectSheet.obj = obj)"
    >
    </intract-create-edit-sheet>
    <!-- delete subject confirmation -->
    <confirmation-dialog
      :visible="showDeleteSubjectDialog"
      title="Are you sure you want to delete this subject?"
      description="Deleting this subject will also delete all the academic material associated with this subject. Proceed with caution."
      @successCallback="deleteSubject"
      @failureCallback="
        subjectOptions.selectedSubject = null;
        showDeleteSubjectDialog = false;
      "
      delete-dialog
    />
    <!-- add subject fab button -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading"
        color="primary"
        class="non-focused"
        @click="createEditSubjectSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- qr code sheet -->
    <intract-bottom-sheet
      title="Attendance QR Code"
      v-if="currentUser.is_support_account"
      :visible="qrSheet.visible"
      @close="
        qrSheet.visible = false;
      "
    >
      <div class="text-center my-4">
        <qrcode-vue :value="qrSheet.value" size="300" level="H" class="mb-2" id="qr-code-canvas" />
        <v-btn color="primary" block @click="downloadQRCode">
          <v-icon>mdi-download</v-icon> Download QR Code
        </v-btn>
      </div>
    </intract-bottom-sheet>
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractListSheet from "@components/generics/IntractListSheet";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractBottomSheet from "@components/generics/IntractBottomSheet.vue";
import QrcodeVue from "qrcode.vue";
export default {
  name: "ClassroomDetails",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractListSheet,
    IntractCreateEditSheet,
    IntractSmartList,
    IntractBottomSheet,
    QrcodeVue,
  },
  data() {
    return {
      classroom: null,
      subjects: [],
      extraSubjects: [],
      showClassTeacherSelectionSheet: false,
      showDeleteSubjectDialog: false,
      showRemoveClassTeacherDialog: false,
      removingClassTeacher: null,
      showAssignClassTeacherDialog: false,
      selectedStaff: null,
      createEditSubjectSheet: {
        visible: false,
        editId: null,
        obj: {
          institution: null,
          room: this.$route.params.roomId,
        },
      },

      subjectOptions: {
        visible: false,

        selectedSubject: null,
      },
      qrSheet: {
        visible: false,
        value: null,
      },
    };
  },
  computed: {
    // appBarOptions() {
    //   return {
    //     actions: [
    //       {
    //         id: 1,
    //         title: "Attendance QR Code",
    //         icon: "mdi-qrcode-scan",
    //         onClick: () =>  {this.qrSheet.visible = true},
    //       },
    //     ],
    //   };
    // },

    subjectOptionItems() {
      return [
        {
          title: "Edit Subject",
          icon: "mdi-pencil",
          action: () => {
            this.createEditSubjectSheet.editId =
              this.subjectOptions.selectedSubject.id;
            this.createEditSubjectSheet.visible = true;
          },
        },
        {
          title: "Delete Subject",
          icon: "mdi-delete",
          action: () => (this.showDeleteSubjectDialog = true),
        },
      ];
    },
    formFields() {
      return {
        name: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Subject Name",
          required: true,
          md: 12,
          max: 255,
        },
        faculty: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Assigned Teachers",
          required: false,
          md: 12,
          endpoint: this.Helper.addUrlParams(
            this.endpoints.staffViewSet,
            "paginate=false"
          ),
          multiple: true,
          itemValue: "id",
          itemText: "full_name",
          itemSubtitle: "username",
          items: [],
        },
      };
    },
  },

  methods: {
    async getClassroomDetails() {
      var url =
        this.endpoints.classroomViewSet + this.$route.params.roomId + "/";
      url = this.Helper.addUrlParams(url, [
        "include_counts=true",
        "include_class_teacher=true",
      ]);
      this.classroom = await this.api.call(this.essentials, url);
      // console.log(this.classroom)
      this.generateQRCode()
    },
    async getClassroomSubjects() {
      var url = this.Helper.addUrlParams(this.endpoints.subjectViewSet, [
        "room=" + this.$route.params.roomId,
        "include_faculty=true",
      ]);
      this.subjects = await this.api.call(this.essentials, url);
    },

    // async getClassroomExtraSubjects() {
    //   var url = this.Helper.addUrlParams(this.endpoints.subjectViewSet, [
    //     "eligible_rooms=" + this.$route.params.roomId,
    //     "include_faculty=true",
    //   ]);
    //   this.extraSubjects = await this.api.call(this.essentials, url);
    // },

    async handleStaffSelectionClick(staff) {
      this.showAssignClassTeacherDialog = true;
      // this.classroom.class_teacher = staff;
      this.selectedStaff = staff;
      this.showClassTeacherSelectionSheet = false;
    },

    async assignClassTeacher() {
      this.showAssignClassTeacherDialog = false;
      var staffId = this.selectedStaff.id;
      this.selectedStaff = null;
      var url =
        this.endpoints.classroomViewSet +
        this.classroom.id +
        "/assign-class-teacher/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.POST,
        { class_teachers: this.classroom.class_teachers ? this.classroom.class_teachers.map(ct => ct.id).concat([staffId]): [staffId], edit: false }
      );
      if (response) {
        this.onRefresh();
        this.showSnackbar({
          title: "Successful!",
          text: "The Class Teacher has been assigned successfully",
          type: "success",
        });
      }
    },

    async changeEditStatus() {
      var url =
        this.endpoints.classroomViewSet +
        this.classroom.id +
        "/assign-class-teacher/";
      // var staffId = this.selectedStaff.id;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.POST,
        {
          class_teachers: this.classroom.class_teachers.map((ct) => ct.id),
          edit: this.classroom.edit,
        }
      );
      if (response) {
        this.showSnackbar({
          title: "Successful!",
          text: this.classroom.edit
            ? "The Class Teacher can now edit Fee Details!"
            : "Edit Access has been revoked!",
          type: "success",
        });
      }
    },

    async removeClassTeacher() {
      //console.log("abc")
      this.showRemoveClassTeacherDialog = false;
      var url =
        this.endpoints.classroomViewSet +
        this.classroom.id +
        "/assign-class-teacher/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.POST,
        { class_teachers: this.classroom.class_teachers.filter(ct => ct.id != this.removingClassTeacher.id).map(ct => ct.id), edit: false }
      );
      if (response) {
        //this.classroom.class_teacher = null
        this.onRefresh();
        this.showSnackbar({
          title: "Succesful!",
          text: "The Class Teacher has been removed",
          type: "success",
        });
        this.removingClassTeacher = null;
      }
    },

    async deleteSubject() {
      this.showDeleteSubjectDialog = false;
      var subjectId = this.subjectOptions.selectedSubject.id;
      this.subjectOptions.selectedSubject = null;
      var url = this.endpoints.subjectViewSet + subjectId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.getClassroomSubjects();
        this.showSnackbar({
          title: "Subject has been successfully deleted!",
          type: "success",
        });
      }
    },
    async onRefresh() {
      this.getClassroomDetails();
      this.getClassroomSubjects();
    },

    generateQRCode() {
      let qrObj = {
        room_id: this.classroom.id,
        institution: this.currentInstitution.id,
      };
      this.qrSheet.value = Buffer.from(JSON.stringify(qrObj)).toString("base64");
    },

    async downloadQRCode(){
      var canvas = document
        .getElementById("qr-code-canvas")
        .getElementsByTagName("canvas");
      var imageLink = canvas[0]
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      var filename = this.classroom.sections + " QR Code.png";
      const downloadEssentials = {
        id: this.Helper.generateUID(),
        progress: null,
        file_name: filename,
        featureName: "Attendance",
      };
      await this.api.download(
        this,
        this.essentials,
        imageLink,
        downloadEssentials,
        "downloads/"
      );

      this.showSnackbar({
        title: "QR Code saved to Gallery!",
        type: "success",
      });
    }
  },
  created() {
    this.createEditSubjectSheet.obj.institution = this.currentInstitution.id;
    this.onRefresh();
  },
};
</script>

